(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var Utils = {
  setCookie: function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    cvalue = encodeURIComponent(cvalue);
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  },
  getCookie: function getCookie(cname) {
    var name = cname + "="; // if cookie value is not encoded set it to encoded

    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }

      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }

    return "";
  },
  deleteCookie: function deleteCookie(name) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  },
  isEncoded: function isEncoded(uri) {
    return /\%/i.test(uri);
  },
  getURLParameters: function getURLParameters(paramName) {
    var sURL = window.document.URL.toString();

    if (sURL.indexOf("?") > 0) {
      var arrParams = sURL.split("?");
      var arrURLParams = arrParams[1].split("&");
      var arrParamNames = new Array(arrURLParams.length);
      var arrParamValues = new Array(arrURLParams.length);
      var i = 0;

      for (i = 0; i < arrURLParams.length; i++) {
        var sParam = arrURLParams[i].split("=");
        arrParamNames[i] = sParam[0];
        if (sParam[1] != "") arrParamValues[i] = unescape(sParam[1]);else arrParamValues[i] = "No Value";
      }

      for (i = 0; i < arrURLParams.length; i++) {
        if (arrParamNames[i].toLowerCase() == paramName.toLowerCase()) {
          // alert("Parameter:" + arrParamValues[i]);
          return arrParamValues[i];
        }
      }

      return "No Parameters Found";
    }
  },
  removeParam: function removeParam(key, sourceURL) {
    var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";

    if (queryString !== "") {
      params_arr = queryString.split("&");

      for (var i = params_arr.length - 1; i >= 0; i -= 1) {
        param = params_arr[i].split("=")[0];

        if (param.toLowerCase() === key.toLowerCase()) {
          params_arr.splice(i, 1);
        }
      }

      rtn = rtn + "?" + params_arr.join("&");
    }

    return rtn;
  },
  insertParam: function insertParam(paramKey, paramValue, originHref) {
    if (originHref !== undefined) {
      var hrefFirstCharSlash = originHref.split("/")[0];
      var hrefFirstCharHash = originHref.split("#")[0];

      if (!originHref.includes("javascript:void(0)")) {
        // filter javascript
        if (originHref.includes("?")) {
          if (originHref.indexOf("#") < 0) {
            return originHref + "&" + paramKey + "=" + paramValue;
          } else {
            var href = originHref.split("#");
            return href[0] + "&" + paramKey + "=" + paramValue + "#" + href[1];
          }
        } else {
          if (originHref.indexOf("#") < 0) {
            return originHref + "?" + paramKey + "=" + paramValue;
          } else {
            var href = originHref.split("#");

            if (hrefFirstCharHash === "" || hrefFirstCharSlash === "") {
              return href[0] + "?" + paramKey + "=" + paramValue + "#" + href[1];
            } else {
              return href[0] + "&" + paramKey + "=" + paramValue + "#" + href[1];
            }
          }
        }
      } else {
        return originHref;
      }
    }
  }
};
window.Utils = Utils;
var _default = Utils;
exports.default = _default;

},{}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var Iframe = {
  init: function init() {
    // defer iframe
    var $iframe = $("iframe");

    if ($iframe.length > 0) {
      $iframe.each(function (i, element) {
        var dataSrc = $(element).data("src");

        if (typeof dataSrc !== "undefined") {
          $(element).attr("src", dataSrc);
        }
      });
    }
  }
};
var _default = Iframe;
exports.default = _default;

},{}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var PostcodeCoverage = {
  init: function init() {
    var $coverage = $(".coverage");
    var $coverageFormGroup = $coverage.find(".form-group");
    var apiUrl = $coverage.data("api-url");
    var msgError = $coverage.data("msg-error");
    var msgErrorRequire = $coverage.data("msg-error-require");
    var msgSuccess = $coverage.data("msg-success");
    $(".coverage .form-control").on("input keypress keyup keydown", function () {
      $(this).val($(this).val().replace(/[^0-9]/g, ''));
    });
    $(".coverage .btn").on("click", function (e) {
      var postcode = $coverage.find(".form-control").val();
      $coverageFormGroup.removeClass("has-error").removeClass("has-success");
      $coverageFormGroup.find(".alert").remove();

      if (postcode.length <= 0) {
        $coverageFormGroup.addClass("has-error");
        $coverageFormGroup.append("<div class='alert alert-danger'>" + msgErrorRequire + "</div>");
        $coverageFormGroup.find(".alert").hide().slideDown();
        return;
      }

      $.ajax({
        url: apiUrl + postcode,
        // url: "https://internal.kanika.site/coverage/check?postcode=" + postcode,
        method: "GET",
        success: function success(res) {
          console.log(res);

          if (res.data) {
            $coverageFormGroup.append("<div class='alert alert-success'>" + msgSuccess + "</div>");
            $coverageFormGroup.find(".alert").hide().slideDown();
          } else {
            $coverageFormGroup.addClass("has-error");
            $coverageFormGroup.append("<div class='alert alert-danger'>" + msgError + "</div>");
            $coverageFormGroup.find(".alert").hide().slideDown();
          }
        },
        error: function error(err) {
          console.log(err);
        }
      });
      e.preventDefault();
    });
  }
};
var _default = PostcodeCoverage;
exports.default = _default;

},{}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _utils = _interopRequireDefault(require("../common/utils.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Reseller = {
  init: function init() {
    var $resellerPersonId = $("#resellerperson_id");
    var $resellerPersonName = $(".resellerperson_name");
    var $resellerPersonRemove = $("#resellerperson_remove");

    var resellerID = _utils.default.getURLParameters("resellerID");

    var $topbarSentence = $("#top-reseller .topbar-sentence"); // user manual remove cookie

    $resellerPersonRemove.on("click", function (e) {
      var originUrl = window.location.href;

      if ($resellerPersonId.length) {
        $resellerPersonId.val("");
      }

      $resellerPersonName.text("");
      $topbarSentence.addClass("hidden");

      _utils.default.deleteCookie("resellerPerson");

      window.location.href = _utils.default.removeParam("resellerID", originUrl);
      e.preventDefault();
    });
    $.ajax({
      url: "https://resellerapi.kanika.com.my/External/ResellerList",
      method: "GET",
      success: function success(res) {
        if (res.data !== null) {
          // if url contain resellerID
          if (resellerID !== undefined && resellerID !== "No Parameters Found") {
            var matched = false;
            var i = 0;

            for (i = 0; i < res.data.length; i++) {
              if (res.data[i].value === resellerID) {
                if ($resellerPersonId.length) {
                  $resellerPersonId.val(res.data[i].value);
                }

                $resellerPersonName.text(res.data[i].key + " (" + res.data[i].value + ")");
                $topbarSentence.removeClass("hidden");
                var resellerPersonData = {
                  name: res.data[i].key,
                  id: res.data[i].value
                }; // set cookie

                resellerPersonData = JSON.stringify(resellerPersonData);

                _utils.default.setCookie("resellerPerson", resellerPersonData, 20 * 365);

                matched = true;
              }
            } // if resellerID match with list we modify all the href so that we can cache the resellerID to any internal page
            // this is for prevent if the user disable cookie in their browser we still able to set the resellerID to the hidden field


            if (matched) {
              // set all href contain parameter
              $("a").each(function (e) {
                var href = $(this).attr("href");
                $(this).attr("href", _utils.default.insertParam("resellerID", resellerID, href));
              });
            } else {
              // if resellerID not match with list check fallback to cookies
              var cookieResellerPerson = _utils.default.getCookie("resellerPerson");

              if (cookieResellerPerson) {
                cookieResellerPerson = JSON.parse(cookieResellerPerson);

                if ($resellerPersonId.length > 0) {
                  $resellerPersonId.val(cookieResellerPerson.id);
                }

                $resellerPersonName.text(cookieResellerPerson.name + " (" + cookieResellerPerson.id + ")");
                $topbarSentence.removeClass("hidden");
              }
            } // if url didnt contain resellerID we get reseller id from cookies and set to input field

          } else {
            var cookieResellerPerson = _utils.default.getCookie("resellerPerson");

            if (cookieResellerPerson) {
              cookieResellerPerson = JSON.parse(cookieResellerPerson);

              if ($resellerPersonId.length > 0) {
                $resellerPersonId.val(cookieResellerPerson.id);
              }

              $resellerPersonName.text(cookieResellerPerson.name + " (" + cookieResellerPerson.id + ")");
              $topbarSentence.removeClass("hidden");
            }
          }
        } else {
          console.log(res);
        }
      },
      error: function error(err) {
        console.log(err);
      }
    });
  }
};
var _default = Reseller;
exports.default = _default;

},{"../common/utils.js":1}],5:[function(require,module,exports){
"use strict";

var _iframe = _interopRequireDefault(require("./components/iframe.js"));

var _reseller = _interopRequireDefault(require("./components/reseller.js"));

var _postcodeCoverage = _interopRequireDefault(require("./components/postcode-coverage.js"));

var _home = _interopRequireDefault(require("./pages/home.js"));

var _about = _interopRequireDefault(require("./pages/about.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

(function ($) {
  $(document).ready(function () {
    _reseller.default.init();

    _postcodeCoverage.default.init();

    _home.default.init();

    _about.default.initCarousel();
  });
  $(window).on("load", function () {
    _iframe.default.init();
  });
})(jQuery);

},{"./components/iframe.js":2,"./components/postcode-coverage.js":3,"./components/reseller.js":4,"./pages/about.js":6,"./pages/home.js":7}],6:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var About = {
  initCarousel: function initCarousel() {
    if ($(".section-feel-like-family").length) {
      $(".section-feel-like-family .owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        navigation: true,
        pagination: true,
        items: 1,
        singleItem: true,
        navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"]
      });
      $("body").addClass("page_about_us");
    }
  }
};
var _default = About;
exports.default = _default;

},{}],7:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var Home = {
  init: function init() {
    if ($("#common-home").length) {
      $("body").addClass("home");
    }
  }
};
var _default = Home;
exports.default = _default;

},{}]},{},[5]);
